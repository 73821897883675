import { Link } from "@/components";
import { IViewTableColumn } from "@/components/table/ViewTable";
import { routerMap } from "@/router/agent_router";
import WebApi from "@/web/api";
import React from "react";
import CommonReport from "../components/CommonReport";

export default class SaleOrderReportPage extends React.PureComponent {
  private summaryColumns = [
    {
      title: '进货单(笔)',
      field: 'totalOrderCount',
    },
    {
      title: '商品数量(主计量单位)',
      field: 'totalProductCount',
    },
    {
      title: '进货总额(元)',
      field: 'totalOrderAmount',
      precision: 2
    },
    {
      title: '进货供应商(家)',
      field: 'totalSupplierCount',
    },
  ];

  private columns: IViewTableColumn[] = [
    {
      title: '日期',
      dataIndex: 'date',
      width: 200,
      align: 'center',
      render: (value, row: any) => {
        if (row.orderCount == 0) {
          return value;
        }
        return <Link to={routerMap.reportPurchaseOrderDetail.getPath(row['date'])}>{value}</Link>
      }
    },
    {
      title: '进货单(笔)',
      dataIndex: 'orderCount',
      align: 'center',
      sorter: (a: any, b: any) => a.orderCount - b.orderCount,
    },
    {
      title: '进货总额(元)',
      dataIndex: 'orderAmount',
      align: 'center',
      sorter: (a: any, b: any) => a.orderAmount - b.orderAmount,
    },
    {
      title: '商品数量(主计量单位)',
      dataIndex: 'productCount',
      align: 'center',
      sorter: (a: any, b: any) => a.productCount - b.productCount,
    },
    {
      title: '进货供应商(家)',
      dataIndex: 'supplierCount',
      align: 'center',
      sorter: (a: any, b: any) => a.supplierCount - b.supplierCount,
    }
  ];
  render() {
    return <CommonReport
      filter={data => {
        return data.filter(i => i.orderCount > 0);
      }}
      showEmptyLabelText="仅显示有订单发生的日期"
      tableColumns={this.columns}
      summaryColumns={this.summaryColumns}
      pagination={false}
      fetch={data => WebApi.report_purchase_order(data)}
    />
  }
}